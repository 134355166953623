<div class="container-fluid pageTitle">
    <div class="container">
        <div class="row">
            <div class="col-md-12">
                <h3>Privacy Policy</h3>
            </div>
        </div>
    </div>
</div>

<div class="container my-5">
    <div class="row">
        <div class="col-md-12">
            <div class="pageContent">
				<p>Below, in 12 simple steps, we explain how we work to make you feel confident in leaving your personal information to us and knowing that we meet the requirements that are required to protect your privacy.</p>
				<h3> 1. General about GDPR </h3>
				<p> From 25 May 2018, the Data Protection Regulation (DGPR) applies. GDPR replaces the existing Personal Information Act (PUL) and applies to all EU countries. GDPR has been developed to protect you as a customer and your rights regarding the handling and dissemination of your personal information. Current Swedish PUL is actually the basis for GDPR and most of PUL is found in GDPR.</p>
				<h3> 2. What is a personal task?</h3>
				<p> A personal data is such information that can be directly or indirectly linked to you as a person. Personal identification number, name, address, telephone, e-mail address etc. Non-personal information about bookings, sales and activities is not included as this is classified as business secrets and in many cases subject to accounting rules and accounting obligations as Getbetal as companies must comply with.</p>
				<h3> 3. Processing of personal data</h3>
				<p> Getbetal wants you to feel safe when you submit your personal information to us. We protect your personal privacy and therefore processing of personal data is based on the requirements contained in the Data Protection Ordinance (GDPR) and other applicable laws.</p>
				<h3> 4. Data controller</h3>
				<p> Getbetal is the personally responsible person responsible for the processing of personal data. </p>
				<h3> 5. When is processing of personal data?</h3>
				<p>Getbetal treats your personal information:</p>
				<p>- When applying for and solving membership of Getbetal</p>
				<p>- when you use our service to transfer money </p>
				<p>- when you sign up for any of our services, newsletters, etc.</p>
				<p>- When you apply for vacant posts or report your interest in employment with us</p>
				<p>- when you register to get help by our support team</p>
				<p>- When you contact us in general, for example, by email and at our social media</p>
				<p>- when you participate in different types of surveys we perform</p>
				<p>- when you are a supplier to us</p>
				<p>- when you are a customer of us</p>
				<h3>6. Registered data </h3>
				<p>We primarily register only contact details such as name, address, phone number and e-mail address. In order to secure identification, we sometimes register the membership register also with social security numbers.</p>
				<p>Personal identification number is a desirable personal information for us as this is our only ability to secure identification and thus combine our various registries to ultimately merge them into one and provide you with the best possible service and experience of our services and our communication . E.g. by avoiding duplicate mailings.</p>
				<h3>7. Purpose of processing of personal data</h3>
				<p>The personal information you provide to us is stored in our systems and used for the purposes they are intended for, and to keep you informed about our offers. Previous purchase behaviors may be used as a basis for selecting the offers that are relevant to you.</p>
				<p>When you apply for vacant posts, submit your interest in employment, participate in surveys, or contact us, we will use your personal information only for the purpose for which you have submitted them.</p>
				<h3>8. Disclosure of personal data</h3>
				<p>Getbetal does not provide personal information to third parties without your approval. However, we use system vendors who indirectly access the personal data, as well as other agents that are necessary to fulfill our commitments. For example, be for invoice delivery, printing of training shirts, etc. With all the suppliers we use, we have personal data protection agreements that protect your personal information from unauthorized processing. Our partners are carefully selected to help us deliver the commitments we have towards you.</p>
				<p>Getbetal does not provide personal information to third parties without your approval. However, we use system vendors who indirectly access the personal data, as well as other agents that are necessary to fulfill our commitments. For example, be for invoice delivery, printing of training shirts, etc. With all the suppliers we use, we have personal data protection agreements that protect your personal information from unauthorized processing. Our partners are carefully selected to help us deliver the commitments we have towards you.</p>
				<h3>9. How long are the data left?</h3>
				<p>We keep your personal information only for as long as it is necessary to fulfill our commitment or the requirements of laws and regulations. If our commitment only consists in sending information, ie if you are only subscribed to our newsletter, you can choose to terminate this at any time, as instructed in the letter.</p>
				<h3>10. Security and protection of personal data</h3>
				<p>Getbetal has set up procedures for the introduction of the GDPR and has taken the necessary security measures to protect personal data from improper access, change and destruction.</p>
				<h3>11. Correction, deletion and access</h3>
				<p>You are entitled to know what personal information we have stored about you. If you find incorrect personal information about you, you may request that they be corrected or removed. Contact your regular contact person or alternatively info@getbetal.com.</p>
				<p>Once a year you have the right to request a free trial of the personal information we have about you. For this we need your written request. Enter your social security number and your contact details, sign the request and send it to our address:</p>
				<p>Getbetal . Noravägen 1 69153  karlskoga, Sweden</p>
				<h3>12. Contact</h3>
				<p>If you have any questions about the processing of personal data, please contact us by e-mail info@getbetal.com.</p>
			</div>
        </div>
    </div>
</div>