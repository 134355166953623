<div class="container-fluid pageTitle">
    <div class="container">
        <div class="row">
            <div class="col-md-12">
                <h3>Contact Us</h3>
            </div>
        </div>
    </div>
</div>

<div class="container">
    <div class="row py-5">
        <div class="col-md-6 ms-auto">
            <div class="row mb-3">
                <div class="col-md-12">
                    <img src="assets/images/logo.png" alt="getbetal" class="img-fluid mb-4" width="250px"> <br>
                </div>
            </div>
            <div class="row">
                <div class="col-md-6">
                    <h6>CALL US</h6><hr>
                    <p class=""><img src="assets/images/flags/Sweden.png" height="25px"> +46-102520670</p>
                    <p ><img src="assets/images/flags/United-Kingdom.png" height="25px"> +44-7572978577
                    </p>
                    <p ><img src="assets/images/flags/Germany.png" height="25px"> +49-15213211630</p>
                </div>
                <div class="col-md-6">
                    <h6>SEND A MESSAGE</h6><hr>
                    <p ><i class="fa-brands fa-whatsapp"></i> +46-102520670</p>
                    <p >support@getbetal.com</p>
                </div>
            </div>
            <div class="row mt-3">
                <div class="col-md-12">
                    <h6>LOCATE US</h6><hr>
                    <p class=""><i class="fa-solid fa-location-dot px-2"></i>Noravägen, 1 691 53 Karlskoga, Sweden.</p>
                </div>
            </div>
        </div>

        <div class="col-md-6 my-auto">
            <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2035.1400742373423!2d14.548577000000002!3d59.3306152!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x465c8a6d6170bdd7%3A0x6af230f26572c7cf!2sNorav%C3%A4gen%201%2C%20691%2053%20Karlskoga%2C%20Sweden!5e0!3m2!1sen!2sin!4v1727447470456!5m2!1sen!2sin" width="100%" height="410" style="border:0;" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
        </div>
    </div>