<div class="container-fluid pageTitle">
    <div class="container">
        <div class="row">
            <div class="col-md-12">
                <h3>Help</h3>
            </div>
        </div>
    </div>
</div>

<div class="container my-5">
    <div class="row">
        <div class="col-md-12 pageContent">
            <h3>Company</h3>
            <p>We have been working with money transfer since 2003. We would like to say that we are experts in the area of sending money world wide. Together with our money transfer partner we have a rock solid system to keep your transfer safe and secure. At the same time we follow all laws and regulations that keeps the world safe. With the power of Bank ID we created the most secure sign-on check. We keep your details encrypted in our vault and only our support team together with the money sending service can see the most necessary details to complete the transfer task.  Our head office is located in Karlskoga, Sweden.</p>
            <h3>Account</h3>
            <p>Your account will be created once you login with Bank ID. First time you login you need to fill some personal data like e-mail and so on.   When you got an account at Getbetal you are ready to send money all over the world. If it's the first time you should visit our page “How it works” to know more about the process.</p>
            <h3>Register</h3>
            <p>It's super easy to register. Actually it's the same process as login, but you need to fill some information about yourself the first time. Registration starts with you signing up with Bank ID. We collect your name from the database. These fields can not be edited. Fill all blank fields and save. Next time you login everything is already there. If you would like to know what details we save about you, or you want to erase them. Please contact us from the contact page and we help you.</p>
            <h3>Exchange</h3>
            <p>We have been working with money transfer since 2003. Without bragging to much we would like to say that we are experts exchanging money. With good partnership using reliable money sending services we can offer better deals than a regular bank can't compete with.</p>
            <h3>Transfer</h3>
            <p>We offer 3 different ways to transfer the money. The cash method simply works like this: When transfer registration is done and you have been choosing the location where to leave the money you will get a transaction code. Bring the code to the chosen location along with your code. When transferring with Swish you will get instructions at the end of transfer registration process. Use your Swish application, enter the Swishnumber and the amount of money as in the description. If using the Bankgiro payment option you will see description on the last page of transfer registration. Use the bankgiro number together with the amount of money to make a manual payment at your internet bank or local bank office. Do not forget to enter the transaction code so we can track the order. We check our account 14 hours a day to make sure you get the fastest service.</p>
            <h3>Referral</h3>
            <p>We are here to help you. If you have entered wrong kind of information, lost a tracking number or just need guidance we are here to help. Get our <a routerLink="contactus">contact information</a>, feel free to contact us anytime.</p>
        </div>
    </div>
</div>