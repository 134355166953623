<div class="container-fluid pageTitle">
    <div class="container">
        <div class="row">
            <div class="col-md-12">
                <h3>Terms & Conditions</h3>
            </div>
        </div>
    </div>
</div>

<div class="container my-5">
    <div class="row">
        <div class="col-md-12">
            <div class="pageContent">
				<p>These provisions govern Getbetal's and Customer's rights and obligations regarding the use of the payment services Getbetal provides.</p>
				<h3>1. Definitions </h3>
				<p><strong>"Service" / "Services"</strong> refers to the payment services Getbetal Getbetal (org nr 874002-0329) delivers to the Customer under the Agreement. Getbetal hold all intellectual property rights to the Service / Services as well as the right to provide the Service with its functions to the Customer by itself or through designated resellers. </p>
				<p><strong>"Agreement" / "Agreement"</strong> refers to the agreement entered into by Getbetal and Customer through Customer's registration as Customer at Getbetal for use of the Service which includes the acceptance of these Terms of Service. The agreement always includes the application of these terms of use in its latest version.</p>
				<p><strong>"Getbetal"</strong> are Getbetal or the Getbetal designated to provide the Service as well as which has entered into an agreement with the Customer.</p>
				<p><strong>"Customer" / "Customer"</strong> is a natural or legal person who has entered into an agreement with Getbetal on the use of the Services with its in-depth functions.</p>
				<p><strong>"Parties" / "Parties"</strong> refers to Getbetal and the Customer who have entered into an Agreement regarding the Service / Services.</p>
				<p><strong>"Payment transaction"</strong> means transfer of funds initiated by the payer for transfer to a payee designated payee.</p>
				<p><strong>"Payee"</strong> is the person who is the Customer designated recipient of a payment transaction. </p>
				<h3>2. General commitments of the parties, etc.</h3>
				<p>Getbetal and Customer undertakes to comply with these Terms of Service at any time during the provision and use of the Service / Services.</p>
				<p>Both Getbetal and the Customer declare themselves bound by these Terms of Use through the parties' conclusion of the Agreement relating to the Service.</p>
				<p>These Terms of Service are valid until further notice. Getbetal is always entitled to change these Terms of Service. Such a change of terms shall be notified to Customer by means of a sign-up page for the Service or otherwise clear to Customer at least thirty (30) days before the change of terms enters into force.</p>
				<p>If the change of condition is a material disadvantage for the Customer, the Customer owns the right to terminate the agreement from the date of entry into force of the Terms and Conditions. Such termination shall be valid at the latest by the date of entry into force of the current terms and conditions. The parties are always entitled to terminate the Agreement to terminate at the next month change.</p>
				<h3>3. Fees and payment </h3>
				<p>The customer shall pay a fixed fee to Getbetal for the use of Getbetal services. Any remarks against debit must be made by the Customer as soon as possible. Contact details for Getbetal and information about Getbetal fees are always clearly stated on Getbetal's website. </p>
				<h3>4. Provision and handling of information, etc.</h3>
				<p>The Customer, by entering into a Agreement on Use of the Getbetal Services, is entitled to process personal data to the extent required under the Personal Data Act (1998: 204) (PUL).</p>
				<p>Customer agrees by entering into the Agreement that Getbetal processes the Customer's personal data in order for Customer to be able to access and be able to use the Service / Services and to enable Getbetal to administer and enforce the Parties' Agreement. Processing of personal data also happens to enable Getbetal to fulfill its other legal obligations.</p>
				<p>The Customer is responsible for ensuring that all information provided by him for the execution of a payment transaction is accurate. If the financial means on delivery can not be delivered to the payee, the funds will be returned to the Customer immediately. If the reason that the funds could not be delivered is due to the Customer giving incorrect payment information that prevented delivery, Getbetal is entitled to charge a special fee for the additional administration that causes Getbetal. After the Customer has placed an order on a Service in the form of a Payment Transaction, Customer may not cancel the order.</p>
				<h3>5. Intellectual Property Rights</h3>
				<p>Content provided through the Service / Services may be covered by the Copyright Act (1960: 729) on the copyright of literary and artistic works ("Copyright Act"). All Intellectual Property Rights The Customer is granted access through the Service to Getbetal or the third party that through the Service provides information to the Customer does not belong to the Customer. The Customer is not entitled to copy, sell, forward, or otherwise use the information provided through the Service to anyone other than what is stated in this Agreement.</p>
				<h3>6. Liability and Liability</h3>
				<p>Getbetal's liability in connection with the execution of a payment service on behalf of the Customer, unless intentionally or grossly negligent, may be imposed on air freight, limited to the maximum amount, including Getbetal charges for the payment service performed, which served as the basis for the Customer's claim for damages.</p>
				<p>Getbetal is not responsible under any circumstances for damage caused by the Customer giving incorrect information / incorrect information. </p>
				<p>Getbetal is also not liable for indirect damages, such as loss of profits, loss of production, equipment costs, employee recruitment and similar costs or losses that may occur during or in connection with Customer's use of the Services.</p>
				<p>If the Customer violates the contents of the Agreement, the Customer shall be liable for damages to Getbetal for the financial damage caused by the breach of contract for Getbetal. </p>
				<p>Concerning infringements of the rights of others, the terms of this Terms and Conditions apply in particular.
				</p><h3>7. Customer Responsibility</h3>
				<p>The Customer undertakes to fully comply with the Agreement as well as all applicable Swedish and international legislation, in particular regarding such information provided, communicated or otherwise handled to Getbetal and within the context of the Customer's use of the Services as well as the financial resources that Provided through the Service / Services.</p>
				<p>The Customer is responsible for ensuring that all personal data and other information provided to Getbetal are accurate and correct as far as the Customer knows. The Customer shall promptly and promptly rectify a non-correct task once it has come to the Customer's notice. </p>
				<p>The Customer may neither transfer or make any other access to Customer's Login or otherwise allow anyone else access to any of the Customer's personal usernames, passwords and / or codes for identification. </p>
				<p>The Customer is responsible for any action undertaken in the Service / Services by using Customer's username and credentials and / or identification code. The Customer is responsible for the Customer's full right of disposal and complies with all applicable legislation regarding the financial means the Customer intends to convey through Getbetal Services.</p>
				<p>The Customer undertakes to follow all Getbetal's instructions for the use of the Service at any time. </p>
				<p>The Customer is responsible for the availability of such hardware and software as well as access to the Internet connection required for the use of the Service / Services, unless otherwise agreed between the Parties. Getbetal is responsible solely for the delivery of the services provided by Getbetal through the Agreement to the Customer.</p>
				<h3>8. Requirements of a return policy</h3>
				<p>Refund can be made no longer then 3 days from the day customer has done the transaction.
					The amount that will be refunded is without the administration cost and the exchange rate regulated between sent date to refund date. If the transaction has reached the beneficiary the refund will not be made.
					It is possible for customer to have credit instead for next transaction.
					Customer needs to contact Getbetal is written format with date and transaction reference, senders full name, beneficiarys complete full namne and mobile phone. Bank account of the sender.
					If customer wants credit, customer needs then to inform Get Betal in written format with date and transaction reference.
					The bank charge to send the money back to customers will be charged to customer.</p>
				<h3>9. Internet Policy</h3>
				<p>If the Customer or unauthorized user of the Customer's login information tries to destroy, prevent or gain access to information in the Service, Getbetal has unlimited right to turn off Customer, terminate the Agreement with immediate effect and Getbetal is entitled to damages and compensation for damages, including compensation for goodwill losses caused by the Customer.</p>
				<h3>10. Right to the Service / Services</h3>
				<p>Getbetal or Getbetal linked third parties hold copyright and all other intellectual property rights to all programs, presentations, databases and other products and works that the Customer may access through the Service / Services and, therefore, do not belong to the Customer. The Customer may only exercise the above-mentioned rights to the extent expressly stated in the Agreement. </p>
				<h3>11 Force Majeure</h3>
				<p>A party is exempted from the penalty for failure to fulfill certain obligations under this agreement if the failure is based on circumstance ("liberating circumstance") which is beyond the control of the party and prevents its execution. As soon as the obstacle ceases, the obligation shall be fulfilled in an agreed manner. As a liberating circumstance, wars, acts of war, government action or failure, new or amended legislation, labor conflicts and similar circumstances shall be considered as liberating circumstances.</p>
				<p>Regardless of what is said above about exemption from sanction, a party may terminate the agreement for immediate termination if the performance of a particular obligation is delayed by more than three (3) months. </p>
				<h3>12. Messages and Languages</h3>
				<p>Terms and conditions are provided in Swedish. Communication between Getbetal and the customer is also required in the Swedish language. </p>
				<h3>13. Applicable law </h3>
				<p>Under this Agreement, Swedish law shall apply.</p>
				<h3>14. Dispute Resolution</h3>
				<p>Disputes between the parties in connection with the parties' agreements shall be resolved primarily by negotiation between the parties.</p>
				<p>If, despite this, the parties can not agree, the parties' dispute shall be finally settled by the General Court.</p>
			</div>
        </div>
    </div>
</div>