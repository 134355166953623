<div class="container-fluid pageTitle">
    <div class="container">
        <div class="row">
            <div class="col-md-12">
                <h3>How it Works</h3>
            </div>
        </div>
    </div>
</div>

<div class="container my-5">
    <div class="row">
        <div class="col-md-12">
            <h3>1. Sign up and Send money</h3>
            <p>You need to have a valid Bank ID to use our services. When Sign Up for the first time with Bank ID there is some details about yourself that need to be added. Fill in all fields and you are ready to start your first transfer. For returning customers just login with your Bank ID and you are ready. Choose to what country you want to transfer money and set the amount. You will get options of method for receiving countries withdrawal options available. Continue the process and choose one of our payment options.</p><br>
            <h3>2. Exchange the money</h3>
            <p>We offer 3 different ways to transfer the money. The cash method simply works like this: When transfer registration is done and you have been choosing the location where to leave the money you will get a transaction code. Bring the code to the chosen location along with your code. When transferring with Swish you will get instructions at the end of transfer registration process. Use your Swish application, enter the Swishnumber and the amount of money as in the description. If using the Bankgiro payment option you will see description on the last page of transfer registration. Use the bankgiro number together with the amount of money to make a manual payment at your internet bank or local bank office. Do not forget to enter the transaction code so we can track the order. We check our account 14 hours a day to make sure you get the fastest service.</p><br>
            <h3>3. Transfer the money</h3>
            <p>Together with large exchange companies we built this platform. The benefit in that is to pass bank offices high bank fees when exchange and sending money. When choosing receiving country and amount of money our system will calculate and show you exactly how much in Swedish krona you need to pay. We have been working with money transfer since 2003 and are experts in exchanging money.</p>
        </div>
    </div>
</div>