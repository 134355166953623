import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { HomeComponent } from './components/home/home.component';
import { AboutusComponent } from './components/aboutus/aboutus.component';
import { HowitworksComponent } from './components/howitworks/howitworks.component';
import { SupportComponent } from './components/support/support.component';
import { TermsComponent } from './components/terms/terms.component';
import { PrivacypolicyComponent } from './components/privacypolicy/privacypolicy.component';
import { ContactusComponent } from './components/contactus/contactus.component';

const routes: Routes = [
  {path: '', component: HomeComponent},
  {path: 'aboutus', component: AboutusComponent},
  {path: 'contactus', component: ContactusComponent},
  {path: 'howitworks', component: HowitworksComponent},
  {path: 'help', component: SupportComponent},
  {path: 'terms', component: TermsComponent},
  {path: 'privacypolicy', component: PrivacypolicyComponent},
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { scrollPositionRestoration: 'enabled' })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
