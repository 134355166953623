<div class="container-fluid pageTitle">
    <div class="container">
        <div class="row">
            <div class="col-md-12">
                <h3>About Us</h3>
            </div>
        </div>
    </div>
</div>

<div class="container aboutus">
    <div class="row py-5">
        <div class="col-md-6 ms-auto">
            <img src="assets/images/aboutus.png" class="img-fluid">
        </div>
        <div class="col-md-6 my-auto">
            <h1 class="display-5">Get Betal AB <br><span class="text-dark">Safe and Secure</span> <br>Online Money
                Transfer.</h1>
            <div class="my-4">
                <p>We have been working with money transfer since 2003. We would like to say that we are experts in
                    the area of sending money world wide. Together with our money transfer partner we have a rock
                    solid system to keep your transfer safe and secure. At the same time we follow all laws and
                    regulations that keeps the world safe.</p>
                <p>With the power of Bank ID we created the most secure sign-on check. We keep your details
                    encrypted in our vault and only our support team together with the money sending service can see
                    the most necessary details to complete the transfer task.</p>
                <p>Get Betal is an authorized payment institution under the supervision of the Financial Supervisory Authority, the Financial Supervisory Authority, with institution number 45577.</p>    
                <p>Get betal AB Adress is Noravägen 1 691 53 Karlskoga Sweden Telefon Category Payment sinstitution</p>
                <p>Organisationsnummer 556899-9253 Institutnummer at FI 45577 Status Activ, 2014-08-29 License 2019-09-27 Money transfer.</p>
            </div>
        </div>
    </div>
</div>