<section class="footer" id="contactus">
	<div class="container py-5">
		<div class="row py-5">
			<div class="col-lg-3 col-sm-6 col-md-6">
				<div class="widget footer-widget mb-5 mb-lg-0">
					<!-- <h5 class="widget-title">Location</h5>
					<p>Lorem ipsum dolor sit amet consectetur adipisicing elit. </p>
					<ul class="list-unstyled">
						<li>E : support@getbetal.com</li>
						<li>P : +123-456-789</li>
					</ul> -->
					<img src="assets/images/logo.png" alt="getbetal" class="img-fluid mb-4" width="250px"> <br>
					<a href="#"> <i class="fab fa-facebook-f"></i></a> <a href="#"> <i class="fab fa-twitter"></i></a> <a href="#"> <i class="fab fa-pinterest"></i></a> <a href="#"> <i class="fab fa-linkedin"></i></a>
				</div>
			</div>
			<div class="col-lg-2 col-sm-6 col-md-6">
				<div class="widget footer-widget mb-5 mb-lg-0">
					<h6 class="widget-title">GETBETAL AB</h6>
					<ul class="list-unstyled footer-links">
						<li><a routerLink="help">Help</a></li>
						<li><a routerLink="howitworks">How It Works</a></li>
						<li><a routerLink="aboutus">About Us</a></li>
					</ul>
				</div>
			</div>
			<div class="col-lg-2 col-sm-6 col-md-6">
				<div class="widget footer-widget mb-5 mb-lg-0">
					<h6 class="widget-title">QUICK LINKS</h6>
					<ul class="list-unstyled footer-links">
						<li><a routerLink="terms">Terms & Condition</a></li>
						<li><a routerLink="privacypolicy">Privacy Policy</a></li>
						<li><a routerLink="contactus">Contact Us</a></li>
					</ul>
				</div>
			</div>
			<div class="col-lg-5 mr-auto col-sm-6 col-md-6">
				<div class="row">
					<div class="col-md-6">
					<h6>CALL US</h6>
					<p class="text-light mb-0"><img src="assets/images/flags/Sweden.png" height="25px"> +46-102520670</p>
					<p class="text-light mb-0"><img src="assets/images/flags/United-Kingdom.png" height="25px"> +44-7572978577</p>
					<p class="text-light mb-0"><img src="assets/images/flags/Germany.png" height="25px"> +49-15213211630</p>
					</div>
					<div class="col-md-6">
						<h6>SEND A MESSAGE</h6>
						<p class="text-light mb-0"><i class="fa-brands fa-whatsapp"></i> +46-102520670</p>
						<p class="text-light mb-0">support@getbetal.com</p>
						</div>
				</div>
			</div>
		</div>
	</div>
</section>

<div class="footer-btm">
	<div class="container py-3">
		<div class="row">
			<div class="col-lg-6">
				<div class="copyright">
					<p class="mb-0">Copyright © 2022 Getbetal Money Transfer - All Rights Reserved</p>
				</div>
			</div>
			<div class="col-lg-6 text-end">
				<div class="copyright">
					<p class="mb-0">Designed & Developed by <a href="http://tayseertech.com/" target="_blank">Tayseer Technologies</a></p>
				</div>
			</div>
		</div>
	</div>
</div>