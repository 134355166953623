    <div class="container-fluid topbar py-2">
        <div class="container">
            <div class="row">
                <div class="col-md-6">
                    <span><i class="fa-solid fa-location-dot px-2"></i>Noravägen, 1 691 53 Karlskoga, Sweden.</span>
                </div>
                <div class="col-md-6 text-end">
                    <span><i class="fa-solid fa-phone px-2"></i>+46-102520670</span> 
                    <span class="ps-5"><i class="fa-regular fa-envelope px-2"></i>support@getbetal.com</span>
                </div>
            </div>
        </div>
    </div>

    <!-- NAVBAR
    ================================================= -->
    <div class="main-navigation main_menu " id="mainmenu-area">
        <div class="container">
            <nav class="navbar navbar-expand-lg">
                <a class="navbar-brand me-auto" routerLink="/">
                    <img src="assets/images/logo.png" alt="getbetal" class="logo img-fluid">
                </a>
                <!-- Toggler -->
                <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarCollapse" aria-controls="navbarCollapse" aria-expanded="false" aria-label="Toggle navigation">
                    <span class="navbar-toggler-icon"></span>
                  </button>

                <!-- Collapse -->
                <div>
                <div class="collapse navbar-collapse" id="navbarCollapse">
                    <!-- Links -->
                    <ul class="navbar-nav ml-auto">
                    	 <li class="nav-item ">
                            <a routerLink="/" class="nav-link js-scroll-trigger">
                                Home
                            </a>
                        </li>
                        <li class="nav-item ">
                            <a routerLink="aboutus" class="nav-link js-scroll-trigger">
                                About Us
                            </a>
                        </li>
                        <li class="nav-item ">
                            <a routerLink="howitworks" class="nav-link js-scroll-trigger">
                                How it Works
                            </a>
                        </li>
                        <li class="nav-item ">
                            <a routerLink="contactus" class="nav-link">
                                Contact Us
                            </a>
                        </li>
                        <!-- <li class="nav-item">
                            <button type="button" class="btn btn-outline-dark"><i class="fa-regular fa-envelope px-2"></i> Send Message</button>
                        </li> -->
                    </ul>
                </div>
            </div> <!-- / .navbar-collapse -->
            </nav>
        </div> <!-- / .container -->
    </div>
