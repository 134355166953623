<div class="container-fluid slider">
    <div class="container">
        <div class="row slide-one py-5">
            <div class="col-md-6 my-auto text-white py-5 py-5">
                <p class="display-3">Goodbye expensive fees, Hello GetBetal</p>
                <h6 style="color: #f8f8f8;">Trusted by thousand of customer to Send Money Online. <br> Download our iOS an Android mobile apps for free by clicking the store icons.</h6><br><br>
                <a href="#"><img src="assets/images/appstore-icon.png" class="img-fluid me-3" width="30%"></a>
                <a href="#"><img src="assets/images/playstore-icon.png" class="img-fluid" width="30%"></a>
            </div>
            <div class="col-md-6 text-center">
                <img src="assets/images/mobile-apps.png" class="img-fluid" width="80%">
            </div>
        </div>
    </div>
</div>
<div class="container-fluid bg-light">
    <div class="container aboutus py-5">
        <div class="row py-5">
            <div class="col-md-6 ms-auto">
                <img src="assets/images/aboutus.png" class="img-fluid">
            </div>
            <div class="col-md-6 my-auto">
                <h1 class="display-5">Get Betal AB <br><span class="text-dark">Safe and Secure</span> <br>Online Money
                    Transfer.</h1>
                <div class="my-4">
                    <p>We have been working with money transfer since 2003. We would like to say that we are experts in
                        the area of sending money world wide. Together with our money transfer partner we have a rock
                        solid system to keep your transfer safe and secure. At the same time we follow all laws and
                        regulations that keeps the world safe.</p>
                    <p>With the power of Bank ID we created the most secure sign-on check. We keep your details
                        encrypted in our vault and only our support team together with the money sending service can see
                        the most necessary details to complete the transfer task.</p>
                </div>
                <button routerLink="aboutus" type="button" class="btn btn-lg btn-dark me-3"> Read more <i
                        class="fa-solid ps-3 fa-arrow-right fa-fade"></i></button>
            </div>
        </div>
    </div>
</div>
<div class="container-fluid">
    <div class="container whychoseus py-5">
        <div class="row py-5">
            <div class="col-md-12 text-center">
                <h1 class="display-5">Why our <span class="text-dark">Customers Choose</span> us<br>in this digital era.
                </h1>
            </div>
        </div>
        <div class="row py-4">
            <div class="col-md-3">
                <div class="card text-center">
                    <div class="card-body">
                        <i class="fa-solid fa-jet-fighter-up"></i><br>
                        <h5 class="card-title py-3">FAST SERVICE</h5>
                        <p class="card-text">We like speed. When you transfer money or got a question we will be ready
                            to help you in no time.</p>
                    </div>
                </div>
            </div>
            <div class="col-md-3">
                <div class="card text-center">
                    <div class="card-body">
                        <i class="fa-solid fa-headset"></i><br>
                        <h5 class="card-title py-3">BEST SUPPORT</h5>
                        <p class="card-text">Our support team can help you if there is any doubt. Got an issue? please
                            test our knowledge and skils.</p>
                    </div>
                </div>
            </div>
            <div class="col-md-3">
                <div class="card text-center">
                    <div class="card-body">
                        <i class="fa-solid fa-hand-peace"></i><br>
                        <h5 class="card-title py-3">EASY TO USE</h5>
                        <p class="card-text">The system is made to be easy as 1, 2, 3. Signup, Login, Fill your details
                            and you are ready to start transfer.</p>
                    </div>
                </div>
            </div>
            <div class="col-md-3">
                <div class="card text-center">
                    <div class="card-body">
                        <i class="fa-solid fa-money-bill-transfer"></i><br>
                        <h5 class="card-title py-3">SEND SERVICE</h5>
                        <p class="card-text">We send money to all over the world and with many kind of sending and
                            receiving options.</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="container-fluid callout py-5">
    <div class="container text-center">
        <div class="row py-4">
            <div class="col-md-12">
                <h1 class="display-5 text-white">We Make Online Money Transfer Easy.</h1>
            </div>
            <div class="col-md-12">
                <p class="display-6 text-white">Get Betal is an authorized payment institution under the supervision of
                    the <br>Financial Supervisory Authority, the Financial Supervisory Authority, with institution
                    number 45577</p><br>
                <button routerLink="contactus" type="button" class="btn btn-lg btn-dark"> Contact us now <i
                        class="fa-solid ps-3 fa-arrow-right fa-fade"></i></button>
            </div>
        </div>
    </div>
</div>
<div class="container clients py-5">
    <div class="row py-5">
        <div class="col-md-6">
            <h1 class="display-5">We are <span class="text-dark">Trusted by</span><br> our clients. <i
                    class="fa-solid fa-heart fa-beat"></i></h1>
        </div>
        <div class="col-md-6">
            <p class="display-6">We have been working with money transfer since 2003 and are experts in Exchanging
                Money.</p>
        </div>
    </div>
    <div class="row pt-4 pb-5 text-center">
        <div class="col-md-2">
            <img src="assets/images/clients/mpesa.jpg" width="125px">
        </div>
        <div class="col-md-2">
            <img src="assets/images/clients/viatel.jpg" width="125px">
        </div>
        <div class="col-md-2">
            <img src="assets/images/clients/nexus.jpg" width="125px">
        </div>
        <div class="col-md-2">
            <img src="assets/images/clients/bankid.jpg" width="125px">
        </div>
        <div class="col-md-2">
            <img src="assets/images/clients/visa.jpg" width="125px">
        </div>
        <div class="col-md-2">
            <img src="assets/images/clients/mastercard.jpg" width="125px">
        </div>
        <div class="col-md-2">
            <img src="assets/images/clients/trustpayments.jpg" width="125px">
        </div>
        <div class="col-md-2">
            <img src="assets/images/clients/freemarket.jpg" width="125px">
        </div>
        <div class="col-md-2">
            <img src="assets/images/clients/revolut.jpg" width="125px">
        </div>
    </div>
</div>